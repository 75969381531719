import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import MenuPanel from '../menu//MenuPanel';
import Button from 'react-bootstrap/Button';
import { Col, Row } from 'react-bootstrap';
import { SimpleInput } from '../core';
import PdfViewer from './PdfViewer';
/* export interface MenuPanelProps {
  stateAppStore?: StateAppStore;
}*/
const MainLayer = () => {
    // const stateOrder = stateAppStore?.stateOrder ?? 0;
    // const { setValue } = useFormContext(); // retrieve all hook methods
    // const box = useRef<HTMLDivElement | null>(null);
    // useScroll(box, ({ scrollY }) => { setValue('scrollY', scrollY); });
    return (_jsx(Row, { style: { marginTop: '10px' }, children: _jsxs(Col, { sm: 12, md: 6, xl: 4, children: [_jsx(Row, { style: { marginTop: '10px' }, children: _jsx(Col, { md: 12, children: _jsx(MenuPanel, {}) }) }), _jsx(Row, { style: { marginTop: '10px' }, children: _jsxs(Col, { md: 12, children: [_jsx("h1", { children: "PDF Viewer" }), _jsx(PdfViewer, { pdfUrl: 'https://storage.yandexcloud.net/avtoapp.bucket.dev.public/orders/2024/9/30/invoices/ed0c3e9e-5d04-4be8-9e1d-145e6152e020.pdf' })] }) }), _jsx(Row, { children: _jsx(Col, { md: 12, children: _jsx(SimpleInput, { label: 'Email \u0434\u043B\u044F \u043E\u0442\u043F\u0440\u0430\u0432\u043A\u0438 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u043E\u0432', name: 'email', 
                            // placeholder='email'
                            required: true, autoFocus: true }) }) }), _jsx(Row, { children: _jsx(Col, { md: 12, className: 'pt-3', children: _jsx(Button, { className: 'next-button form-button', variant: 'primary', children: "\u0412\u0441\u0451 \u0432\u0435\u0440\u043D\u043E. \u041F\u043E\u043B\u0443\u0447\u0438\u0442\u044C \u0421\u041C\u0421" }) }) })] }) }));
};
export default inject('stateAppStore')(observer(MainLayer));
