import { jsx as _jsx } from "react/jsx-runtime";
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
// import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
/// import demoFile from "./demo.pdf"; 
const PdfViewer = ({ pdfUrl }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    _jsx(Worker, { workerUrl: 'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js', children: _jsx("div", { style: { height: '720px' }, children: _jsx(Viewer, { fileUrl: pdfUrl, plugins: [defaultLayoutPluginInstance] }) }) }));
};
export default PdfViewer;
